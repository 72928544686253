import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import UserTestimonial from "./UserTestimonial"

const TestimonialSection = ({ data }) => {
  console.log(data)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const mobileSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="text-center justify-center bg-off-white py-16 px-4 lg:px-24">
      <div className="text-3xl lg:text-xl font-medium mb-6">
        Hear from our very first users
      </div>
      <div className="hidden lg:block">
        <Slider {...settings}>
          {data.map((userTestimonial) => (
            <UserTestimonial key={userTestimonial.id} data={userTestimonial} />
          ))}
        </Slider>
      </div>
      <div className="lg:hidden">
        <Slider {...mobileSettings}>
          {data.map((userTestimonial) => (
            <UserTestimonial key={userTestimonial.id} data={userTestimonial} />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default TestimonialSection
