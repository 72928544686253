import React from "react"

const UserTestimonial = ({ data }) => {
  return (
    <div className="flex flex-col items-start lg:flex-row  lg:items-center border border-light-gray rounded-xl bg-white py-6 px-6 lg:py-10 lg:px-24 text-black">
      <div className="flex flex-row mb-4 items-center  lg:mb-0 lg:mr-16">
        <div className="flex flex-col justify-center">
          <div className="w-48 text-base text-left font-semibold mb-2 lg:mb-4">
            {data.userTitle}
          </div>
          <div className="w-48 text-left text-base text-primary-blue">
            {data.company}
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.testimonial }}></div>
    </div>
  )
}

export default UserTestimonial
