import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import OverviewBanner from "../components/home/OverviewBanner"
import FeaturesPreview from "../components/home/FeaturesPreview"
import TestimonialSection from "../components/home/TestimonialSection"
import { SecondaryButton } from "../components/Button"

const Home = ({ data }) => {
  const featuresPreviewData = data.allFeaturesPreviewJson.nodes
  const userTestimonialData = data.allUserTestimonialsJson.nodes

  return (
    <Layout>
      <OverviewBanner />
      <TestimonialSection data={userTestimonialData} />
      <FeaturesPreview data={featuresPreviewData} />
      <div className="flex flex-col bg-primary-blue items-center px-12 py-16 lg:py-28 lg:px-24">
        <h1 className="text-3xl text-center text-white mb-6">
          Build the best teams with Baseline
        </h1>
        <a href="https://app.baselineteams.com/register">
          <SecondaryButton>Try for free</SecondaryButton>
        </a>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    allFeaturesPreviewJson {
      nodes {
        id
        title
        subtitle
        description
        imageName
      }
    }
    allUserTestimonialsJson {
      nodes {
        id
        userTitle
        company
        testimonial
      }
    }
  }
`

export default Home
