import React from "react"
import { PrimaryButton } from "../Button"
import { StaticImage } from "gatsby-plugin-image"

const OverviewBanner = () => {
  return (
    <div className="mx-auto py-16 lg:px-16 text-center bg-primary-off-white">
      <div className="text-4xl font-bold  px-4 z-10 lg:font-bold">
        Focus on people and build the best teams
      </div>
      <div className="text-base my-8 px-4 z-10">
        An efficient and cost effective resource planning tool that builds and
        manages the optimal teams for your projects.
      </div>
      <a href="https://app.baselineteams.com/register">
        <PrimaryButton>Try for free</PrimaryButton>
      </a>
      <StaticImage
        className="hidden z-0 py-0 lg:block"
        src="../../images/OverviewBannerImage.png"
        placeholder="blurred"
        alt="banner"
      />
      <StaticImage
        className="z-0 px-12 py-10 lg:hidden"
        src="../../images/OverviewBannerImage_Mobile.png"
        alt="banner"
      />
      <a href="https://app.baselineteams.com/register">
        <PrimaryButton>Sign up now</PrimaryButton>
      </a>
    </div>
  )
}
export default OverviewBanner
