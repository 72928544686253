import React from "react"

const FeaturesPreview = ({ data }) => {
  return (
    <div className="justify-center bg-primary-off-white lg:py-16 ">
      {data.map((featurePreview) => (
        <div
          key={featurePreview.id}
          className="flex flex-col lg:flex-row even:flex-col even:bg-off-white lg:even:flex-row-reverse py-10 px-4 lg:px-24"
        >
          <div className="flex flex-col justify-center flex-1">
            <span className="text-primary-blue font-semibold uppercase mb-2">
              {featurePreview.title}
            </span>
            <h2 className="text-black text-xl font-semibold mb-4">
              {featurePreview.subtitle}
            </h2>
            <p className="text-black text-base font-light">
              {featurePreview.description}
            </p>
          </div>
          <div className="mx-6" />
          <div className="flex-1 mt-4 lg:mt-0">
            <img
              src={require(`../../images/${featurePreview.imageName}.png`)}
              alt="feature preview"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default FeaturesPreview
